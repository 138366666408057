/* eslint-disable lingui/text-restrictions */
/* eslint-disable lingui/no-unlocalized-strings */

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@reactive-resume/ui";

const Question1 = () => (
  <AccordionItem value="2">
    <AccordionTrigger className="text-left leading-relaxed">
      How much does it cost? What is the price?
    </AccordionTrigger>
    <AccordionContent className="prose max-w-none dark:prose-invert">
      <p>
        All the features on the platform are <strong>100% free</strong> and there are no paid
        options or hidden costs.
      </p>

      <p>
        The only thing we ask for is to hear from you in case you have found our platform useful and
        if it has helped you land a job or grow your career.
      </p>

      <p>
        If you want to support us in keeping things free and in adding new career development tools,
        and <em>only if you have some extra money lying around</em> , you can donate by contacting
        us at{" "}
        <a href="mailto:hello@careerbldr.com" rel="noreferrer" target="_blank">
          hello@careerbldr.com
        </a>
        .
      </p>
    </AccordionContent>
  </AccordionItem>
);

const Question2 = () => (
  <AccordionItem value="1">
    <AccordionTrigger className="text-left leading-relaxed">
      How can I get started with using the Resume Builder?
    </AccordionTrigger>
    <AccordionContent className="prose max-w-none dark:prose-invert">
      <p>
        Getting started with your resume is designed to be fast, simple, and effective. After you
        register an account, you will just create a resume. After this, select a CV template from
        from the list and you can start adding sections, changing layouts and colors and so on.
      </p>
      <p>
        For more information, please visit our Documentation. It covers how the generator works, as
        well as extremely detailed guides about the platform and each feature offered.
      </p>
    </AccordionContent>
  </AccordionItem>
);

// How does the OpenAI Integration work?
const Question3 = () => (
  <AccordionItem value="5">
    <AccordionTrigger className="text-left leading-relaxed">
      How does the AI work with the Resume builder?
    </AccordionTrigger>
    <AccordionContent className="prose max-w-none dark:prose-invert">
      <p>
        Our mission is to build products that use Artificial Intelligence, in a responsible way.
        This is why we chose to integrate our online resume generator with OpenAI's ChatGPT. What
        this means is that you are able to instantly generate and edit content with a click of a
        button. The AI features will allow you to create more effective bullet points and content
        for your resume, while also helping you tailor it for specific job descriptions.
      </p>

      <p>
        Currently, you as an user have a choice about whether or not to use the AI features. By
        default, the AI is disabled, so in order to enable it, you need to obtain an API key from
        OpenAI. Then, you just need to insert it in your Account's Settings page (in the OpenAI
        Integration section). <strong>The key is stored in your browser's local storage</strong>,
        which means that if you uninstall your browser, or even clear your data, the key is gone
        with it. All requests made to OpenAI are also sent directly to their service and does not
        hit CareerBldr's premises at all.
      </p>

      <p>
        We are currently working on changing this and enabling the{" "}
        <strong>AI resume generation assistance by default</strong>, and we estimate that we will
        have this done by the end of this quarter, Q1 2024.
      </p>

      <p>
        Furthermore, you are free to turn off all AI features (and not be aware of them being a part
        of the resume building experience) simply by not adding a key in the Settings page, and
        still make use of all the useful features that CareerBldr's resume generator has to offer.
        Also, one thing you can do for now is to just generate content for your resume from an AI of
        your preference: be it ChatGPT, Bard, Grok or so on, and then simply copy it to CareerBldr's
        online resume builder's interface.
      </p>
    </AccordionContent>
  </AccordionItem>
);

// What languages are supported on Reactive Resume?
const Question4 = () => {
  return (
    <AccordionItem value="4">
      <AccordionTrigger className="text-left leading-relaxed">
        Do your provide PDF resume export? What about other export options?
      </AccordionTrigger>
      <AccordionContent className="prose max-w-none dark:prose-invert">
        <p>
          Yes, the option to <strong>export the resume as PDF</strong> is available.
        </p>

        <p>
          Another options that is available is to <strong>export your CV as a JSON file</strong>.
          This option is used to re-import your resume in the future in our resume builder, or to
          simply edit it manually.
        </p>

        <p>
          These are the only types of export options currently available. We do plan to add .doc and
          .docx options sometimes in the future, but there is no current ETA for when that will
          happen.
        </p>
      </AccordionContent>
    </AccordionItem>
  );
};

export const FAQSection = () => (
  <section id="faq" className="container relative py-24 sm:py-32">
    <div className="grid gap-12 lg:grid-cols-3">
      <div className="space-y-6">
        <h2 className="text-4xl font-bold">Frequently Asked Questions</h2>

        <p className="text-base leading-loose">
          Here are some questions that often get asked about CareerBldr.
        </p>

        <p className="text-sm leading-loose">
          For more information about how to use the CV builder, please go over our documentation or
          contact us.
        </p>
      </div>

      <div className="col-span-2">
        <Accordion collapsible type="single">
          <Question1 />
          <Question2 />
          <Question3 />
          <Question4 />
        </Accordion>
      </div>
    </div>
  </section>
);
