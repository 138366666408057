import { t } from "@lingui/macro";

export const SupportSection = () => (
  <section
    id="support"
    className="relative space-y-12 bg-secondary-accent py-24 text-primary sm:py-32"
  >
    <div className="container space-y-6">
      <h1 className="text-4xl font-bold">{t`About CareerBldr`}</h1>

      <p className="max-w-4xl leading-loose">
        {t`CareerBldr is a 100% free platform for building and generating beautiful resumes, online. Supported by the Studio Algorithm NGO, it is a way to fullfil our mission of creating amazing products, infused with artificial intelligence, in a responsible way.`}
      </p>
    </div>
  </section>
);
