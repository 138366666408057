import { t } from "@lingui/macro";

export const ContributorsSection = () => {
  return (
    <section id="contributors" className="container relative space-y-12 py-24 sm:py-32">
      <div className="space-y-6 text-center">
        <h1 className="text-4xl font-bold">{t`Mission-driven`}</h1>
        <p className="mx-auto max-w-3xl leading-loose">
          {t`CareerBldr is driven by a mission to provide AI that enhances extremely valuable use-cases, in a responsible manner. Register your account now and get started.`}
        </p>
      </div>
    </section>
  );
};
