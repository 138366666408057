import { Trans } from "@lingui/macro";
import { cn } from "@reactive-resume/utils";

type Props = {
  className?: string;
};

export const Copyright = ({ className }: Props) => (
  <div
    className={cn(
      "prose prose-sm prose-zinc flex max-w-none flex-col gap-y-1 text-xs opacity-40 dark:prose-invert",
      className,
    )}
  >
    <span>
      <Trans>
        Built by{" "}
        <a target="_blank" rel="noopener noreferrer nofollow" href="https://studioalgorithms.com">
          Studio Algorithm
        </a>
        , NGO
      </Trans>
    </span>

    <span className="mt-4">© Copyright 2024 CareerBldr.com</span>
  </div>
);
